<template>
	<v-flex v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</v-flex>
</template>

<script>
/**
 * @displayName w-flex
 *
 * @since 0.3.2
 */
export default {
	name: 'WFlex'
}
</script>
